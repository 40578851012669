import HomeCard from "./HomeCard";
import UseBreakpoints from "../hooks/ui/UseBreakpoints";
import { device } from "../../style/styled-components/helpers/device";
import { languageStore } from "../../state/language";
import { motion } from "framer-motion";
import styled from "styled-components";
import judgeImage from "../../resources/images/home/judge.webp";
import dutyfreeImage from "../../resources/images/home/pexels-josh-sorenson-1716826.jpg";
import freeTradeImage from "../../resources/images/home/free-trade.webp";
import travellerImage from "../../resources/images/home/pexels-oleksandr-pidvalnyi-1008155.jpg";
import celebrationImage from "../../resources/images/home/pexels-rovenimagescom-949592.jpg";

const HomeTab = () => {
  const { language } = languageStore();
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.4,
      },
    },
  };
  const breakpoints = UseBreakpoints();
  return (
    <Div breakpoints={breakpoints}>
      <div>
        <div>
          <div>News Letter</div>
          <div>{language === "EN" ? "Customs News" : "관세동향"}</div>
          <div>관세, 수출입통관, 무역 관련 정보</div>
        </div>
        <motion.ul
          className="container"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          <motion.li className="item" variants={item}>
            <HomeCard
              content={{
                category: "기타",
                title:
                  "국립전파연구원 적합성평가 민원사례 게시판 구축 및 이용 안내",
                author: "",
                link: "/boards/customsnews/159",
                image: travellerImage,
                date: "2025. 1. 17",
              }}
            />
          </motion.li>

          <motion.li className="item" variants={item}>
            <HomeCard
              content={{
                category: "FTA",
                title: "(아세안) 특혜관세 사후신청 규정 운영 현황 및 신청조건",
                author: "",
                link: "/boards/customsnews/158",
                image: freeTradeImage,
                date: "2025. 1. 17",
              }}
            />
          </motion.li>

          <motion.li className="item" variants={item}>
            <HomeCard
              content={{
                category: "개정법령",
                title: "관세법 일부개정법률",
                author: "",
                link: "/boards/customsnews/157",
                image: judgeImage,
                date: "2025. 1. 17",
              }}
            />
          </motion.li>
        </motion.ul>
      </div>
      <div>
        <div>
          <div>What's new?</div>
          <div>{language === "EN" ? "A-One News" : "A-One 소식"}</div>
          <div>에이원의 새소식과 구성원의 기고문</div>
        </div>
        <motion.ul
          className="container"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          <motion.li className="item" variants={item}>
            <HomeCard
              content={{
                category: "에이원 소식",
                title: "자본감소 공고",
                author: "관세법인에이원",
                link: "/boards/aonenews/7",
                date: "2024.07.25.",
                image: judgeImage,
              }}
              colored
            />
          </motion.li>
          <motion.li className="item" variants={item}>
            <HomeCard
              content={{
                category: "에이원 소식",
                title: "정운기 관세동우회장, ‘2023 HDI 인간경영대상’ 수상",
                author: "관세법인에이원",
                link: "/boards/aonenews/6",
                date: "2024.01.05.",
                image: celebrationImage,
              }}
              colored
            />
          </motion.li>
          <motion.li className="item" variants={item}>
            <HomeCard
              content={{
                category: "에이원 소식",
                title: "2023년 관세평가 품목분류 연구논문 분야 각각 수상",
                author: "관세법인에이원",
                link: "/boards/aonenews/5",
                date: "2024.01.05.",
                image: celebrationImage,
              }}
              colored
            />
          </motion.li>
        </motion.ul>
      </div>
    </Div>
  );
};
export default HomeTab;
const Div = styled.div`
  display: flex;
  flex-direction: ${({ breakpoints }) => (breakpoints.lg ? "row" : "column")};
  // news letter, what's new 공통
  & > div {
    min-height: 20rem;
    flex: 1;
    display: flex;
    flex-direction: ${({ breakpoints }) =>
      // breakpoints.xl ? "row" :
      breakpoints.md ? "column" : "column"};
    & > div {
      display: flex;
      justify-content: ${({ breakpoints }) =>
        breakpoints?.xl ? "stretch" : breakpoints.md ? "right" : "center"};
      flex: 1;
      &:first-child {
        padding: 2em 0 0 3em;
        display: flex;
        flex-direction: column;
        /* color: white; */
        & > div:first-child {
          // 영문 헤더
          font-size: 1.5em;
          font-weight: lighter;
        }
        & > div:nth-child(2) {
          // 큰글씨 헤더
          font-size: 3em;
          font-weight: bold;
        }
        & > div:nth-child(3) {
          padding: 0.3em 0;
          font-size: 1.1em;
        }
      }
    }
    & > ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      padding: 1rem 0;
      @media ${device.mobileL} {
        flex-direction: row;
        align-items: stretch;
        padding: 1rem;
        justify-content: flex-start;
      }
      margin: 0;
      flex: 1;
      & > li {
        width: auto;
        & > div {
          // framer motion에서 생기는 마진 초기화
          margin: 0;
        }
      }
    }
    &:nth-child(2) {
      // What's new 배경
      background-color: ${({ theme }) => theme?.colors?.medium};
      color: white;
    }
  }
`;
